<template>
    <v-card class="mx-auto" min-width=95%>
          <v-card-title>
            Empleados
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar usuario"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="empleados"
            :search="search"
          >   
          </v-data-table>
      </v-card>
</template>
<script>
export default {
    props: ['empleados'],
    data(){
        return{
            search: null,
            headers: [
                {text: 'Nombre', value: 'nombre'},
                { text: 'Identificacion', value: 'identificacion' },
                { text: 'Correo', value: 'correo' },
            ]
        }
    }
}
</script>