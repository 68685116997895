<template>
  <div>
    <v-main>
      <v-container fluid>
        <v-row>
         
          <v-col cols="12">
            
            <v-sheet width="" height="" elevation="" rounded>
              <v-container fluid>
                
                <v-sheet
                  color="indigo darken-4"
                  class="mt-2"
                  dark
                  elevation="5"
                  rounded
                  height=""
                  width=""
                >
                
                  <v-row justify="start">
                    <v-col cols="1">
                      <!-- $router.replace(
                            '/supervisor/home_supervisor/Casos Asignados/' +
                              $store.state.tipo_para_arreglar_problas_de_ruta
                          ); -->
                      <!-- $router.replace('/supervisor/home_supervisor/Casos Asignados/'+$store.state.tipo_para_arreglar_problas_de_ruta); -->
                      <v-icon
                        class="ml-4 mt-2"
                        @click="
                          
                         $router.go(-1)
                          $store.state.mostrarCasos = true;
                          $store.state.mostrarDatos = false;
                        "
                        
                      >
                        mdi-chevron-left
                      </v-icon>
                    </v-col>
                    <v-col cols="8" md="8" xs="8" sm="8">
                      <div
                        v-text="'Folders: ' + parametrosRuta.title"
                        class="headline font-weight-light mt-1"
                      ></div>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-container>

              <v-container fluid>
                 
                <v-sheet>
                    
                  <v-window v-model="window_supervisor" class="">
                    <v-window-item>
                      <v-row>
                        <v-col>
                          <v-sheet width="" height="" elevation="" rounded>
                            <v-container fluid class="fill-height">
                              <v-row>
                                <v-col>
                                  <v-sheet>
                                    <v-row>
                                      <v-col>
                                        <v-sheet
                                         
                                          elevation=""
                                          rounded=""
                                          class="mt-n10"
                                        >
                                      
                                          <v-row>
                                            <v-col>
                                              <div
                                                v-text="'Meta data'"
                                                class="headline font-weight-light text-center blue darken-4 white--text rounded mx-5"
                                              ></div>
                                            </v-col>
                                          </v-row>
                                        </v-sheet>
                                      </v-col>
                                    </v-row>
                                    <v-container
                                      fluid
                                      class="fill-height mx-10"
                                    >
                                      <div
                                        v-text="'Detalles'"
                                        class="header font-weight-light text-left"
                                      ></div>
                                      <v-row>
                                        <v-col>
                                          <v-row>
                                            <v-col>
                                              <v-container>
                                                <v-row>
                                                  <v-col cols="1">
                                                    <v-icon
                                                      >mdi-file-document</v-icon
                                                    >
                                                  </v-col>
                                                  <v-col>
                                                    <div
                                                      class="title font-weight-bold"
                                                      v-text="'Tipo documental'"
                                                    ></div>
                                                    <div
                                                      v-text="
                                                        parametrosRuta.tipoDocumental
                                                      "
                                                      class="body-1 font-weight-light text-left ml-5"
                                                    ></div>
                                                  </v-col>
                                                </v-row>
                                              </v-container>
                                            </v-col>
                                            <v-col>
                                              <v-container>
                                                <v-row>
                                                  <v-col cols="1">
                                                    <v-icon>mdi-account</v-icon>
                                                  </v-col>
                                                  <v-col>
                                                    <div
                                                      class="title font-weight-bold"
                                                      v-text="'Enviado por'"
                                                    ></div>
                                                    <div
                                                      v-text="
                                                        parametrosRuta.remitente
                                                      "
                                                      class="subtitle font-weight-light text-left ml-5"
                                                    ></div>
                                                  </v-col>
                                                </v-row>
                                              </v-container>
                                            </v-col>
                                          </v-row>
                                          <v-row>
                                            
                                            <v-col>
                                              <v-container>
                                                <v-row>
                                                  <v-col cols="1">
                                                    <v-icon
                                                      >mdi-message</v-icon
                                                    >
                                                  </v-col>
                                                  <v-col>
                                                    <div
                                                      class="title font-weight-bold"
                                                      v-text="
                                                        'Comentario'
                                                      "
                                                    ></div>
                                                    <div
                                                      v-text="parametrosRuta.data.comentario"
                                                        
                                                     
                                                      class="subtitle font-weight-light text-left ml-5"
                                                    ></div>
                                                  </v-col>
                                                </v-row>
                                              </v-container>
                                            </v-col>
                                           
                                            <v-col>
                                              <v-container>
                                                <v-row>
                                                  <v-col cols="1">
                                                    <v-icon
                                                      >mdi-calendar-check</v-icon
                                                    >
                                                  </v-col>
                                                  <v-col>
                                                    <div
                                                      class="title font-weight-bold"
                                                      v-text="
                                                        'Fecha de asignación'
                                                      "
                                                    ></div>
                                                    <div
                                                      v-text="
                                                        parametrosRuta.data
                                                          .fecha_asignacion_caso
                                                      "
                                                      class="subtitle font-weight-light text-left ml-5"
                                                    ></div>
                                                  </v-col>
                                                </v-row>
                                                <v-row>
                                                  <v-col cols="1">
                                                    <v-icon
                                                      >mdi-calendar-check</v-icon
                                                    >
                                                  </v-col>
                                                  <v-col>
                                                    <div
                                                      class="title font-weight-bold"
                                                      v-text="
                                                        'Fecha limite de entrega'
                                                      "
                                                    ></div>
                                                    <div
                                                      v-text="
                                                        fecha_limite
                                                      "
                                                      class="subtitle font-weight-light text-left ml-5"
                                                    ></div>
                                                  </v-col>
                                                </v-row>
                                              </v-container>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-row>

                                      <!-- <v-row
                                    v-if="
                                      parametrosRuta.data.estado == 'Pendiente'
                                    "
                                  >
                                    <v-col>
                                      <v-btn
                                        color="red"
                                        text
                                        @click="rechazar_caso"
                                        >Rechazar caso</v-btn
                                      >
                                    </v-col>
                                    <v-col>
                                      <v-btn
                                        block
                                        text
                                        color="indigo"
                                        @click="aceptar_caso"
                                        >Aceptar caso</v-btn
                                      >
                                    </v-col>
                                  </v-row> -->
                                      <v-row>
                                        <v-col cols="10">
                                          <v-list>
                                            <v-list-item>
                                              <v-list-item-icon
                                                ><v-icon color="blue darken-4"
                                                  >mdi-information</v-icon
                                                ></v-list-item-icon
                                              >
                                              <v-list-item-content>
                                                <v-list-item-title
                                                  >Información publica del caso
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                  Archivos bases los cuales
                                                  podras revisar siempre que
                                                  quieras
                                                </v-list-item-subtitle>
                                              </v-list-item-content>

                                              <v-list-item-action>
                                                <v-btn
                                                  small
                                                  text
                                                  color="blue darken-4"
                                                  @click="
                                                   back()
                                                  "
                                                >
                                                  REVISAR
                                                </v-btn>
                                              </v-list-item-action>
                                            </v-list-item>
                                            <v-list-item>
                                              <v-list-item-icon
                                                ><v-icon color="blue darken-4"
                                                  >mdi-share-variant</v-icon
                                                ></v-list-item-icon
                                              >
                                              <v-list-item-content>
                                                <v-list-item-title
                                                  >Flujo de trabajo
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                  Flujos
                                                </v-list-item-subtitle>
                                              </v-list-item-content>

                                              <v-list-item-action>
                                                <v-btn
                                                  small
                                                  text
                                                  color="blue darken-4"
                                                  @click="window_supervisor = 1"
                                                >
                                                  VER FLUJOS
                                                </v-btn>
                                              </v-list-item-action>
                                            </v-list-item>
                                            <v-list-item
                                              v-if="
                                                parametrosRuta.data.estado ==
                                                'Aceptado'
                                              "
                                            >
                                              <v-list-item-icon
                                                ><v-icon color="blue darken-4"
                                                  >mdi-account-multiple</v-icon
                                                ></v-list-item-icon
                                              >
                                              <v-list-item-content>
                                                <v-list-item-title
                                                  >Empleados trabajando en el
                                                  caso
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                  Empleados que participaron en
                                                  el caso
                                                </v-list-item-subtitle>
                                              </v-list-item-content>

                                              <v-list-item-action>
                                                <v-btn
                                                  small
                                                  text
                                                  color="blue darken-4"
                                                  @click="window_supervisor = 2"
                                                >
                                                  EMPLEADOS DEL CASO</v-btn
                                                >
                                              </v-list-item-action>
                                            </v-list-item>
                                            <v-list-item
                                              v-if="
                                                parametrosRuta.data.estado ==
                                                'Aceptado'
                                              "
                                            >
                                              <v-list-item-icon
                                                ><v-icon color="blue darken-4"
                                                  >mdi-account-multiple-plus</v-icon
                                                ></v-list-item-icon
                                              >
                                              <v-list-item-content>
                                                <v-list-item-title
                                                  >Asignar caso a empleado
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                  Asignar caso a empleados
                                                </v-list-item-subtitle>
                                              </v-list-item-content>

                                              <v-list-item-action>
                                                <v-btn
                                                  small
                                                  text
                                                  color="blue darken-4"
                                                  @click="window_supervisor = 3"
                                                >
                                                  Asignar caso</v-btn
                                                >
                                              </v-list-item-action>
                                            </v-list-item>
                                          </v-list>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-sheet>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-sheet>
                        </v-col>
                      </v-row>
                    </v-window-item>
                    <v-window-item>
                      <v-row>
                        <v-col>
                          <v-btn
                            @click="window_supervisor = 0"
                            block
                            text
                            color="red"
                            > <v-icon class="mx-2">mdi-arrow-left</v-icon> Volver</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <div
                            v-text="'Flujo de trabajo'"
                            class="headline font-weight-light text-center"
                          ></div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <FlowyPlugin :nodes="parametrosRuta.data.workflow" />
                        </v-col>
                      </v-row>
                    </v-window-item>
                    <v-window-item
                      v-if="parametrosRuta.data.estado == 'Aceptado'"
                    >
                      <v-row>
                        <v-col>
                          <v-btn
                            @click="window_supervisor = 0"
                            block
                            text
                            color="red"
                            > <v-icon class="mx-2">mdi-arrow-left</v-icon> Volver</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <TablaEmpleados :empleados="empleados_en_el_caso" />
                        </v-col>
                      </v-row>
                    </v-window-item>
                    <v-window-item
                      v-if="parametrosRuta.data.estado == 'Aceptado'"
                    >
                      <v-row>
                        <v-col>
                          <v-btn
                            @click="window_supervisor = 0"
                            block
                            text
                            color="red"
                            > <v-icon class="mx-2">mdi-arrow-left</v-icon> Volver</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <Delegar :parametrosRuta="parametrosRuta" />
                        </v-col>
                      </v-row>
                    </v-window-item>
                  </v-window>
                </v-sheet>
              </v-container>
              
            </v-sheet>
            
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>
<script>
//const axios = require("axios").default;
import TablaEmpleados from "../../components/supervisor/TablaEmpleados";
import FlowyPlugin from "../../components/admin/flowy-vue";
import Delegar from "../../components/supervisor/Delegar";
import acciones_casos_supervisor from "../../mixins/acciones_casos_supervisor";
export default {
  mixins: [acciones_casos_supervisor], //Funciones del mixin (aceptar_caso)/ rechazar_caso
  components: {
    TablaEmpleados,
    FlowyPlugin,
    Delegar,
  },
  props: ["padre"],
  data() {
    return {
      window_supervisor: 0,
      parametrosRuta: {},
      panel: [0],
      fecha_limite: "",
      empleados_en_el_caso: [
        //$route.params.data.data.responsables
      ],
    };
  },
  async created() {
    console.log(this.$route.params.id)
    let decode = atob(this.$route.params.id)
    console.log(decode);
    decode = JSON.parse(decode);
    console.log('decode')
    console.log(decode);
    this.parametrosRuta = decode.data;
    console.log(this.parametrosRuta)
    this.fecha_limite = await this.$store.dispatch('workflows/fecha_limite',{
      fecha_inicial: this.parametrosRuta.data.fecha_asignacion_caso, tipo_documental: this.parametrosRuta.data.tipo_documental
    })
    console.log(this.fecha_limite)
    this.empleados_en_el_caso = this.parametrosRuta.data.responsables;
    console.log(this.parametrosRuta);
  },
  methods: {
    revisar_archivos_caso(ruta) {
      this.$router.push({
        name: "revisar_caso_supervisor",
        params: {
          id: this.$route.params.id,
          data: this.parametrosRuta,
          action: ruta,
        },
      });
    },
    back() {
      let id = {
        id: this.$route.params.id,
        data: this.parametrosRuta
      };
      id = JSON.stringify(id); //Convierte a string el objeto de arriba
      console.log(id);
      id = btoa(id); //Convierte a base 64 el struing de arriba
      console.log(id); //Asegurate que sea unico

      this.$router.push({
        name: "revisar_caso_supervisor",
        params: { id: id },
      });

      //Para descodificarlo en la vista harias:

      //En este momento decode debe tener el objeto {id: '1231231231231',  data: {nombre:'nombre',token: 'token',}}
      //Por tanto a la variable de this.parametrosRuta.data le asignas decode.data y el id no se que haras con el
    },
  },
};
</script>