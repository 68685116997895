var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"width":"","height":"","elevation":"","rounded":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',{staticClass:"mt-2",attrs:{"color":"indigo darken-4","dark":"","elevation":"5","rounded":"","height":"","width":""}},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{staticClass:"ml-4 mt-2",on:{"click":function($event){_vm.$router.go(-1)
                        _vm.$store.state.mostrarCasos = true;
                        _vm.$store.state.mostrarDatos = false;}}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-col',{attrs:{"cols":"8","md":"8","xs":"8","sm":"8"}},[_c('div',{staticClass:"headline font-weight-light mt-1",domProps:{"textContent":_vm._s('Folders: ' + _vm.parametrosRuta.title)}})])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',[_c('v-window',{model:{value:(_vm.window_supervisor),callback:function ($$v) {_vm.window_supervisor=$$v},expression:"window_supervisor"}},[_c('v-window-item',[_c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"width":"","height":"","elevation":"","rounded":""}},[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-sheet',[_c('v-row',[_c('v-col',[_c('v-sheet',{staticClass:"mt-n10",attrs:{"elevation":"","rounded":""}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"headline font-weight-light text-center blue darken-4 white--text rounded mx-5",domProps:{"textContent":_vm._s('Meta data')}})])],1)],1)],1)],1),_c('v-container',{staticClass:"fill-height mx-10",attrs:{"fluid":""}},[_c('div',{staticClass:"header font-weight-light text-left",domProps:{"textContent":_vm._s('Detalles')}}),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',[_vm._v("mdi-file-document")])],1),_c('v-col',[_c('div',{staticClass:"title font-weight-bold",domProps:{"textContent":_vm._s('Tipo documental')}}),_c('div',{staticClass:"body-1 font-weight-light text-left ml-5",domProps:{"textContent":_vm._s(
                                                      _vm.parametrosRuta.tipoDocumental
                                                    )}})])],1)],1)],1),_c('v-col',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-col',[_c('div',{staticClass:"title font-weight-bold",domProps:{"textContent":_vm._s('Enviado por')}}),_c('div',{staticClass:"subtitle font-weight-light text-left ml-5",domProps:{"textContent":_vm._s(
                                                      _vm.parametrosRuta.remitente
                                                    )}})])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',[_vm._v("mdi-message")])],1),_c('v-col',[_c('div',{staticClass:"title font-weight-bold",domProps:{"textContent":_vm._s(
                                                      'Comentario'
                                                    )}}),_c('div',{staticClass:"subtitle font-weight-light text-left ml-5",domProps:{"textContent":_vm._s(_vm.parametrosRuta.data.comentario)}})])],1)],1)],1),_c('v-col',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',[_vm._v("mdi-calendar-check")])],1),_c('v-col',[_c('div',{staticClass:"title font-weight-bold",domProps:{"textContent":_vm._s(
                                                      'Fecha de asignación'
                                                    )}}),_c('div',{staticClass:"subtitle font-weight-light text-left ml-5",domProps:{"textContent":_vm._s(
                                                      _vm.parametrosRuta.data
                                                        .fecha_asignacion_caso
                                                    )}})])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',[_vm._v("mdi-calendar-check")])],1),_c('v-col',[_c('div',{staticClass:"title font-weight-bold",domProps:{"textContent":_vm._s(
                                                      'Fecha limite de entrega'
                                                    )}}),_c('div',{staticClass:"subtitle font-weight-light text-left ml-5",domProps:{"textContent":_vm._s(
                                                      _vm.fecha_limite
                                                    )}})])],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"blue darken-4"}},[_vm._v("mdi-information")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Información publica del caso ")]),_c('v-list-item-subtitle',[_vm._v(" Archivos bases los cuales podras revisar siempre que quieras ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"small":"","text":"","color":"blue darken-4"},on:{"click":function($event){return _vm.back()}}},[_vm._v(" REVISAR ")])],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"blue darken-4"}},[_vm._v("mdi-share-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Flujo de trabajo ")]),_c('v-list-item-subtitle',[_vm._v(" Flujos ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"small":"","text":"","color":"blue darken-4"},on:{"click":function($event){_vm.window_supervisor = 1}}},[_vm._v(" VER FLUJOS ")])],1)],1),(
                                              _vm.parametrosRuta.data.estado ==
                                              'Aceptado'
                                            )?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"blue darken-4"}},[_vm._v("mdi-account-multiple")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Empleados trabajando en el caso ")]),_c('v-list-item-subtitle',[_vm._v(" Empleados que participaron en el caso ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"small":"","text":"","color":"blue darken-4"},on:{"click":function($event){_vm.window_supervisor = 2}}},[_vm._v(" EMPLEADOS DEL CASO")])],1)],1):_vm._e(),(
                                              _vm.parametrosRuta.data.estado ==
                                              'Aceptado'
                                            )?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"blue darken-4"}},[_vm._v("mdi-account-multiple-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Asignar caso a empleado ")]),_c('v-list-item-subtitle',[_vm._v(" Asignar caso a empleados ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"small":"","text":"","color":"blue darken-4"},on:{"click":function($event){_vm.window_supervisor = 3}}},[_vm._v(" Asignar caso")])],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('v-window-item',[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"block":"","text":"","color":"red"},on:{"click":function($event){_vm.window_supervisor = 0}}},[_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-arrow-left")]),_vm._v(" Volver")],1)],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"headline font-weight-light text-center",domProps:{"textContent":_vm._s('Flujo de trabajo')}})])],1),_c('v-row',[_c('v-col',[_c('FlowyPlugin',{attrs:{"nodes":_vm.parametrosRuta.data.workflow}})],1)],1)],1),(_vm.parametrosRuta.data.estado == 'Aceptado')?_c('v-window-item',[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"block":"","text":"","color":"red"},on:{"click":function($event){_vm.window_supervisor = 0}}},[_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-arrow-left")]),_vm._v(" Volver")],1)],1)],1),_c('v-row',[_c('v-col',[_c('TablaEmpleados',{attrs:{"empleados":_vm.empleados_en_el_caso}})],1)],1)],1):_vm._e(),(_vm.parametrosRuta.data.estado == 'Aceptado')?_c('v-window-item',[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"block":"","text":"","color":"red"},on:{"click":function($event){_vm.window_supervisor = 0}}},[_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-arrow-left")]),_vm._v(" Volver")],1)],1)],1),_c('v-row',[_c('v-col',[_c('Delegar',{attrs:{"parametrosRuta":_vm.parametrosRuta}})],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }