<template>
  <div>
    <div class="column">
      <div
        class="flex-grow overflow-auto"
        style="width:100%;"
      >
        <flowy
          class="mx-auto"
          :nodes="nodes"
          :beforeMove="beforeMove"
          :beforeAdd="beforeAdd"
          @add="add"
          @move="move"
          @remove="remove"
          @drag-start="onDragStart"
        ></flowy>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Vue from "vue";
import FlowyPlugin from "@hipsjs/flowy-vue";
import "@hipsjs/flowy-vue/dist/lib/flowy-vue.css";
Vue.use(FlowyPlugin);

/*
 * We define this component inline for it to work with CodePen
 */


/*
 * We define this block inline for it to work with CodePen
 */

//import DemoBlock from './DemoBlock'
//import DemoNode from './DemoNode'
import {mapState} from 'vuex'

export default {
  components: { 
    //DemoBlock, 
    //DemoNode 
  },
  props: ['nodes'],
  data: () => ({
    holder: [],
    dragging: false,
    blocks: [
      {
        preview: {
          title: 'New visitor',
        },
        node: {
          title: 'New visitor',
          description: '<span>When a <b>new visitor</b> goes to <b>Site 1</span></span>',
        },
      },
      {
        preview: {
          title: 'Update database',
          icon: 'error',
        },
        node: {
          title: 'Update database',
          description: '<span>Triggers when somebody performs a <b>specified action</b></span>',
        },
      },
      {
        preview: {
          title: 'Time has passed',
        },
        node: {
          title: 'Time has passed',
          description: 'Triggers after a specified <b>amount</b> of time',
        },
      },
      {
        preview: {
          title: 'Cannot be added',
        },
        node: {
          title: 'Time has passed',
          description: 'Triggers after a specified <b>amount</b> of time',
          canBeAdded: false,
        },
      },
    ],
  }),
  created(){
    console.log(this.nodes)
    if(this.nodes.length==0){
      let nodo = {
        id: '1',
        parentId: -1,
        nodeComponent: 'demo-node',
        data: {
          text: 'Parent block',
          title: 'Inicio',
          description: 'A partir de aqui crea el recorrido del documento por las distintas areas de su entidad. Considera el punto de inicio como el area que recibe ingreso de datos, Ejm: Recepcion ',
          area_id: -1
        },
      }
      this.nodes.push(nodo)
    }
  },
  methods: {
    onDragStartNewBlock (event) {
      console.log('onDragStartNewBlock', event);
      // contains all the props and attributes passed to demo-node
      const { props } = event
      this.newDraggingBlock = props;
    },
    onDragStopNewBlock (event) {
      console.log('onDragStopNewBlock', event);
      this.newDraggingBlock = null;
    },
    // REQUIRED
    beforeMove ({ to, from }) {
      // called before moving node (during drag and after drag)
      // indicator will turn red when we return false
      // from is null when we're not dragging from the current node tree
      console.log('beforeMove', to, from);

      // we cannot drag upper parent nodes in this demo
      if (from && from.parentId === -1) {
        return false;
      }
      // we're adding a new node (not moving an existing one)
      if (from === null) {
        // we've passed this attribute to the demo-node
        if (this.newDraggingBlock['custom-attribute'] === false) {
          return false
        }
      }

      return true;
    },
    // REQUIRED
    beforeAdd ({ to, from }) {
      // called before moving node (during drag and after drag)
      // indicator will turn red when we return false
      // from is null when we're not dragging from the current node tree
      console.log('beforeAdd', to, from);

      // we've passed this attribute to the demo-node
      if (this.newDraggingBlock['custom-attribute'] === false) {
        return false
      }

      return true;
    },
    randomInteger () {
      return Math.floor(Math.random() * 10000) + 1;
    },
    generateId (nodes) {
      let id = this.randomInteger();
      // _.find is a lodash function
      while (_.find(nodes, { id }) !== undefined) {
        id = this.randomInteger();
      }
      return id;
    },
    addNode (_event) {
      const id = this.generateId();
      this.nodes.push({
        ..._event.node,
        id,
      });
      console.log(this.nodes)
    },
    remove (event) {
      console.log('remove', event)

      // node we're dragging to
      const { node } = event

      // we use lodash in this demo to remove node from the array
      const nodeIndex = _.findIndex(this.nodes, { id: node.id });
      this.nodes.splice(nodeIndex, 1);
      //this.$store.state.admin.workflow.splice(nodeIndex,1)
    },
    move (event) {
      console.log('move', event);

      // node we're dragging to and node we've just dragged
      const { dragged, to } = event;

      // change panentId to id of node we're dragging to
      dragged.parentId = to.id;
    },
    add (event) {
      // every node needs an ID
      console.log(event)
      const id = this.generateId();

      // add to array of nodes
      let data = {
        title: event.area.nombre,
        area_id: event.area.objectId,
        text: 'El tipo documental ingresara a esta area una vez haya culminado el proceso en el area de: '+event.nodo.data.title,
        description: 'El tipo documental ingresara a esta area una vez haya culminado el proceso en el area de: '+event.nodo.data.title
      
      }
      this.nodes.push({
        id: id,
        data: data,
        nodeComponent: event.nodo.nodeComponent,
        parentId: event.nodo.id,
      }); 


      /*
       this.$store.state.admin.workflow.push({
         id: id,
         data: data,
         nodeComponent: event.nodo.nodeComponent,
         parentId: event.nodo.id
       })
       */

      //En el momento de mandar mis datos al endpoint debo validar que si existe el id debe ir con su parentID
      //Porque se puede dar el caso de que los id no existan y por tanto los parendId no deberia ir al endpoint

      //Cuando traiga los datos del worfkFlow debo tener en cuenta que: Los parentId seran los 
      //sectores a los cuales deberan ir dirigidas las respuestas del caso es decir que
      //De el sector 1 si bifurca 3 en lugares debere recorrer todo el array buscando quien tiene
      //Como parentId a 1 lo cual me arrojaria 3 sectores (los 3 de la bifurcacion)
       console.log(this.nodes)
    },
    onDragStart (event) {
      console.log('onDragStart', event);
      this.dragging = true;
    },
  },
  computed: {
    ...mapState({
      agregar_nodo: function (state) {
        return state.admin.agregar_nodo;
      },
      guardar_nodos: function (state) {
        return state.admin.guardar_nodos;
      },
    }),
  } ,
  watch: {
      agregar_nodo: function () {
      //console.log("prueba", this.actualizarDocumentos);
      console.log('Iniciando?')
      this.add(this.$store.state.admin.nodo)
      //this.addNode(this.$store.state.admin.nodo)
    },
    guardar_nodos: function() {
      this.$store.state.admin.workflow = this.nodes

      if(this.$store.state.admin.proceso=='crear'){
        this.$emit('terminar')
      }else{
        this.$emit('editar')
      }
      console.log(this.$store.state.admin.workflow)
      
    }
  },
};

</script>

<style lang="scss">
.flowy-drag-handle button {
  cursor: grab;
}

.flowy-block.draggable-mirror {
  opacity: 0.6;
}
</style>